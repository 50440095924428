import React from "react";
import { Menu } from "antd";
import { NavLink, useParams, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { SideBarStyle } from "./style";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, "");
    }
  });
  path = path.slice(0, path.length - 1);
  return path;
};

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, tokenId }) => {
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);
  console.log(path, "path.....");
  let query = useQuery();

  const tokenName = query.get("name");
  const tokenSymbol = query.get("symbol");

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");
  console.log(mainPathSplit, "mainPath slplit....");
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [
          `${
            mainPathSplit.length > 2 ? mainPathSplit[1] : "community-dashboard"
          }`,
        ]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <SideBarStyle>
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
        theme={darkMode && "dark"}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length === 1
                    ? "community-dashboard"
                    : mainPathSplit.length === 2
                    ? mainPathSplit[1]
                    : mainPathSplit[2]
                }`,
              ]
            : []
        }
        defaultOpenKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length > 2
                    ? mainPathSplit[1]
                    : "community-dashboard"
                }`,
              ]
            : []
        }
        overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        openKeys={openKeys}
      >
        <Menu.Item
          key="community-dashboard"
          icon={<FeatherIcon icon="home" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/community-dashboard/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Dashboard
          </NavLink>
        </Menu.Item>
        <Menu.Item key="tokens" icon={<FeatherIcon icon="mail" size={18} />}>
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/tokens/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Tokens
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="users"
          icon={<FeatherIcon icon="message-square" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/users/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Users
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="buy"
          icon={<FeatherIcon icon="shopping-cart" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/buy/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Buy
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="sell"
          icon={<FeatherIcon icon="shopping-cart" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/sell/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Sell
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="airdrop"
          icon={<FeatherIcon icon="shopping-cart" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/airdrop/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Airdrop
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="history"
          icon={<FeatherIcon icon="calendar" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/history/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            History
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="integrations"
          icon={<FeatherIcon icon="layout" size={18} />}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/integrations/${tokenId}?name=${tokenName}&symbol=${tokenSymbol}`}
          >
            Integrations
          </NavLink>
        </Menu.Item>
      </Menu>
    </SideBarStyle>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
