const actions = {
  POPULAR_TOKENS_BEGIN: 'POPULAR_TOKENS_BEGIN',
  POPULAR_TOKENS_SUCCESS: 'POPULAR_TOKENS_SUCCESS',
  POPULAR_TOKENS_ERR: 'POPULAR_TOKENS_ERR',

  popularTokensBegin: () => {
    return {
      type: actions.POPULAR_TOKENS_BEGIN,
    };
  },

  popularTokensSuccess: (data) => {
    return {
      type: actions.POPULAR_TOKENS_SUCCESS,
      data,
    };
  },

  popularTokensErr: (err) => {
    return {
      type: actions.POPULAR_TOKENS_ERR,
      err,
    };
  },
};

export default actions;
