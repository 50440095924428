import actions from './actions';

const initialState = {
  historyListData: null,
  hlLoading: false,

  error: null,
};

const { HISTORY_LIST_BEGIN, HISTORY_LIST_SUCCESS, HISTORY_LIST_ERR } = actions;

const historyReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case HISTORY_LIST_BEGIN:
      return {
        ...state,
        hlLoading: true,
      };
    case HISTORY_LIST_SUCCESS:
      return {
        ...state,
        historyListData: data,
        hlLoading: false,
      };
    case HISTORY_LIST_ERR:
      return {
        ...state,
        error: err,
        hlLoading: false,
      };

    default:
      return state;
  }
};

export default historyReducer;
