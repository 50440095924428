const actions = {
  HISTORY_LIST_BEGIN: 'HISTORY_LIST_BEGIN',
  HISTORY_LIST_SUCCESS: 'HISTORY_LIST_SUCCESS',
  HISTORY_LIST_ERR: 'HISTORY_LIST_ERR',

  historyListBegin: () => {
    return {
      type: actions.HISTORY_LIST_BEGIN,
    };
  },
  historyListSuccess: (data) => {
    return {
      type: actions.HISTORY_LIST_SUCCESS,
      data,
    };
  },
  historyListErr: (err) => {
    return {
      type: actions.HISTORY_LIST_ERR,
      err,
    };
  },
};

export default actions;
