import actions from './actions';

const initialState = {
  tokenDetailsData: null,
  tdLoading: false,

  totalIssuance: null,

  exchangeRateData: null,
  erLoading: false,

  top5UsersData: null,
  t5uLoading: false,

  mostRecentActionsData: null,
  msaLoading: false,

  latestUsersData: null,
  luLoading: false,

  tokenStatsData: null,
  tsLoading: false,

  error: null,
};

const {
  TOKEN_DETAILS_BEGIN,
  TOKEN_DETAILS_SUCCESS,
  TOKEN_DETAILS_ERR,
  SET_TOTAL_ISSUANCE,
  EXCHANGE_RATE_BEGIN,
  EXCHANGE_RATE_SUCCESS,
  EXCHANGE_RATE_ERR,
  TOP_5_USERS_BEGIN,
  TOP_5_USERS_SUCCESS,
  TOP_5_USERS_ERR,
  MOST_RECENT_ACTIONS_BEGIN,
  MOST_RECENT_ACTIONS_SUCCESS,
  MOST_RECENT_ACTIONS_ERR,
  LATEST_USERS_BEGIN,
  LATEST_USERS_SUCCESS,
  LATEST_USERS_ERR,
  TOKEN_STATS_BEGIN,
  TOKEN_STATS_SUCCESS,
  TOKEN_STATS_ERR,
} = actions;

const communityDashboardReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_TOTAL_ISSUANCE:
      return {
        ...state,
        totalIssuance: data,
      };

    case TOKEN_DETAILS_BEGIN:
      return {
        ...state,
        tdLoading: true,
      };
    case TOKEN_DETAILS_SUCCESS:
      return {
        ...state,
        tokenDetailsData: data,
        tdLoading: false,
      };
    case TOKEN_DETAILS_ERR:
      return {
        ...state,
        error: err,
        tdLoading: false,
      };

    case EXCHANGE_RATE_BEGIN:
      return {
        ...state,
        erLoading: true,
      };
    case EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        exchangeRateData: data,
        erLoading: false,
      };
    case EXCHANGE_RATE_ERR:
      return {
        ...state,
        error: err,
        erLoading: false,
      };

    case TOP_5_USERS_BEGIN:
      return {
        ...state,
        t5uLoading: true,
      };
    case TOP_5_USERS_SUCCESS:
      return {
        ...state,
        top5UsersData: data,
        t5uLoading: false,
      };
    case TOP_5_USERS_ERR:
      return {
        ...state,
        error: err,
        t5uLoading: false,
      };

    case MOST_RECENT_ACTIONS_BEGIN:
      return {
        ...state,
        msaLoading: true,
      };
    case MOST_RECENT_ACTIONS_SUCCESS:
      return {
        ...state,
        mostRecentActionsData: data,
        msaLoading: false,
      };
    case MOST_RECENT_ACTIONS_ERR:
      return {
        ...state,
        error: err,
        msaLoading: false,
      };

    case LATEST_USERS_BEGIN:
      return {
        ...state,
        luLoading: true,
      };
    case LATEST_USERS_SUCCESS:
      return {
        ...state,
        latestUsersData: data,
        luLoading: false,
      };
    case LATEST_USERS_ERR:
      return {
        ...state,
        error: err,
        luLoading: false,
      };

    case TOKEN_STATS_BEGIN:
      return {
        ...state,
        tsLoading: true,
      };
    case TOKEN_STATS_SUCCESS:
      return {
        ...state,
        tokenStatsData: data,
        tsLoading: false,
      };
    case TOKEN_STATS_ERR:
      return {
        ...state,
        error: err,
        tsLoading: false,
      };

    default:
      return state;
  }
};

export default communityDashboardReducer;
