import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "../container/profile/authentication/Index";

const Login = lazy(() =>
  import("../container/profile/authentication/overview/SignIn")
);
const SignUp = lazy(() =>
  import("../container/profile/authentication/overview/Signup")
);
const ForgotPass = lazy(() =>
  import("../container/profile/authentication/overview/ForgotPassword")
);

const NotFound = () => {
  return <Route render={() => <Navigate to="/" replace />} />;
};

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route path="forgotPassword" element={<ForgotPass/>} />
        <Route path="register" element={<SignUp/>} />
        <Route path="" element={<Login/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Suspense>
  );
};

export default AuthLayout(FrontendRoutes);
