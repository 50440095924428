import actions from './actions';

const initialState = {
  usersListData: null,
  ulLoading: false,

  error: null,
};

const { USERS_LIST_BEGIN, USERS_LIST_SUCCESS, USERS_LIST_ERR } = actions;

const usersReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USERS_LIST_BEGIN:
      return {
        ...state,
        ulLoading: true,
      };
    case USERS_LIST_SUCCESS:
      return {
        ...state,
        usersListData: data,
        ulLoading: false,
      };
    case USERS_LIST_ERR:
      return {
        ...state,
        error: err,
        ulLoading: false,
      };

    default:
      return state;
  }
};

export default usersReducer;
