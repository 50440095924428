import { actions } from "./actions";

const initialState = {
    isTransacting: false,

    isTransactionSuccess: false,

    spotPrice: null,
    totalIssuance: null,
};

const {
    MAKING_TRANSACTION_CALL,
    TRANSACTION_SUCCESS,
    TRANSACTION_FAILURE,
    SET_SPOT_PRICE,
    SET_TOTAL_ISSUANCE,
} = actions;

const blockchainIntegrationReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SET_TOTAL_ISSUANCE:
            return {
                ...state,
                totalIssuance: data,
            };
        case SET_SPOT_PRICE:
            return {
                ...state,
                spotPrice: data,
            };

        case MAKING_TRANSACTION_CALL:
            return {
                ...state,
                isTransacting: true,
                isTransactionSuccess: false,
            };
        case TRANSACTION_SUCCESS:
            return {
                ...state,
                isTransactionSuccess: true,
                isTransacting: false,
            };
        case TRANSACTION_FAILURE:
            return {
                ...state,
                isTransactionSuccess: false,
                isTransacting: false,
            };

        default:
            return state;
    }
};

export default blockchainIntegrationReducer;
