import actions from './actions';

const initialState = {
  popularTokensData: null,
  ptLoading: false,

  error: null,
};

const {
  POPULAR_TOKENS_BEGIN,
  POPULAR_TOKENS_SUCCESS,
  POPULAR_TOKENS_ERR,
} = actions;

const dashboardReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case POPULAR_TOKENS_BEGIN:
      return {
        ...state,
        ptLoading: true,
      };
    case POPULAR_TOKENS_SUCCESS:
      return {
        ...state,
        popularTokensData: data,
        ptLoading: false,
      };
    case POPULAR_TOKENS_ERR:
      return {
        ...state,
        error: err,
        ptLoading: false,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
