const utils = {
  paramConversion: {
    num: [
      'Compact<Balance>',
      'BalanceOf',
      'u8',
      'u16',
      'u32',
      'u64',
      'u128',
      'i8',
      'i16',
      'i32',
      'i64',
      'i128',
    ],
  },
}

export default utils
