import React from "react";
import propTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ Component }) => {
  const isLoggedIn = useSelector((state) => state.auth.login);
  return isLoggedIn ? <Component /> : <Navigate to="/" />;
};

ProtectedRoute.propTypes = {
  Component: propTypes.object.isRequired,
};

export default ProtectedRoute;
