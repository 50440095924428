const actions = {
    MAKING_TRANSACTION_CALL: "MAKING_TRANSACTION_CALL",
    TRANSACTION_SUCCESS: "TRANSACTION_SUCCESS",
    TRANSACTION_FAILURE: "TRANSACTION_FAILURE",

    SET_SPOT_PRICE: "SET_SPOT_PRICE",

    SET_TOTAL_ISSUANCE: "SET_TOTAL_ISSUANCE",

    setTotalIssuance: (issuance) => {
        return {
            type: actions.SET_TOTAL_ISSUANCE,
            data: issuance,
        };
    },

    setSpotPrice: (spotPrice) => {
        return {
            type: actions.SET_SPOT_PRICE,
            data: spotPrice,
        };
    },

    makingTransactionCall: () => {
        return {
            type: actions.MAKING_TRANSACTION_CALL,
        };
    },

    transactionSuccess: () => {
        return {
            type: actions.TRANSACTION_SUCCESS,
        };
    },

    transactionFailure: () => {
        return {
            type: actions.TRANSACTION_FAILURE,
        };
    },
};

export { actions };
