const actions = {
  USERS_LIST_BEGIN: 'USERS_LIST_BEGIN',
  USERS_LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  USERS_LIST_ERR: 'USERS_LIST_ERR',

  usersListBegin: () => {
    return {
      type: actions.USERS_LIST_BEGIN,
    };
  },
  usersListSuccess: (data) => {
    return {
      type: actions.USERS_LIST_SUCCESS,
      data,
    };
  },
  usersListErr: (err) => {
    return {
      type: actions.USERS_LIST_ERR,
      err,
    };
  },
};

export default actions;
