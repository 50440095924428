const actions = {
  TOKEN_DETAILS_BEGIN: 'TOKEN_DETAILS_BEGIN',
  TOKEN_DETAILS_SUCCESS: 'TOKEN_DETAILS_SUCCESS',
  TOKEN_DETAILS_ERR: 'TOKEN_DETAILS_ERR',

  SET_TOTAL_ISSUANCE: 'SET_TOTAL_ISSUANCE',

  EXCHANGE_RATE_BEGIN: 'EXCHANGE_RATE_BEGIN',
  EXCHANGE_RATE_SUCCESS: 'EXCHANGE_RATE_SUCCESS',
  EXCHANGE_RATE_ERR: 'EXCHANGE_RATE_ERR',

  TOP_5_USERS_BEGIN: 'TOP_5_USERS_BEGIN',
  TOP_5_USERS_SUCCESS: 'TOP_5_USERS_SUCCESS',
  TOP_5_USERS_ERR: 'TOP_5_USERS_ERR',

  MOST_RECENT_ACTIONS_BEGIN: 'MOST_RECENT_ACTIONS_BEGIN',
  MOST_RECENT_ACTIONS_SUCCESS: 'MOST_RECENT_ACTIONS_SUCCESS',
  MOST_RECENT_ACTIONS_ERR: 'MOST_RECENT_ACTIONS_ERR',

  LATEST_USERS_BEGIN: 'LATEST_USERS_BEGIN',
  LATEST_USERS_SUCCESS: 'LATEST_USERS_SUCCESS',
  LATEST_USERS_ERR: 'LATEST_USERS_ERR',

  TOKEN_STATS_BEGIN: 'TOKEN_STATS_BEGIN',
  TOKEN_STATS_SUCCESS: 'TOKEN_STATS_SUCCESS',
  TOKEN_STATS_ERR: 'TOKEN_STATS_ERR',

  setTotalIssuance: (issuance) => {
    return {
      type: actions.SET_TOTAL_ISSUANCE,
      data: issuance,
    };
  },

  tokenDetailsBegin: () => {
    return {
      type: actions.TOKEN_DETAILS_BEGIN,
    };
  },
  tokenDetailsSuccess: (data) => {
    return {
      type: actions.TOKEN_DETAILS_SUCCESS,
      data,
    };
  },
  tokenDetailsErr: (err) => {
    return {
      type: actions.TOKEN_DETAILS_ERR,
      err,
    };
  },

  exchangeRateBegin: () => {
    return {
      type: actions.EXCHANGE_RATE_BEGIN,
    };
  },
  exchangeRateSuccess: (data) => {
    return {
      type: actions.EXCHANGE_RATE_SUCCESS,
      data,
    };
  },
  exchangeRateErr: (err) => {
    return {
      type: actions.EXCHANGE_RATE_ERR,
      err,
    };
  },

  top5UsersBegin: () => {
    return {
      type: actions.TOP_5_USERS_BEGIN,
    };
  },
  top5UsersSuccess: (data) => {
    return {
      type: actions.TOP_5_USERS_SUCCESS,
      data,
    };
  },
  top5UsersErr: (err) => {
    return {
      type: actions.TOP_5_USERS_ERR,
      err,
    };
  },

  mostRecentActionsBegin: () => {
    return {
      type: actions.MOST_RECENT_ACTIONS_BEGIN,
    };
  },
  mostRecentActionsSuccess: (data) => {
    return {
      type: actions.MOST_RECENT_ACTIONS_SUCCESS,
      data,
    };
  },
  mostRecentActionsErr: (err) => {
    return {
      type: actions.MOST_RECENT_ACTIONS_ERR,
      err,
    };
  },

  latestUsersBegin: () => {
    return {
      type: actions.LATEST_USERS_BEGIN,
    };
  },
  latestUsersSuccess: (data) => {
    return {
      type: actions.LATEST_USERS_SUCCESS,
      data,
    };
  },
  latestUsersErr: (err) => {
    return {
      type: actions.LATEST_USERS_ERR,
      err,
    };
  },

  tokenStatsBegin: () => {
    return {
      type: actions.TOKEN_STATS_BEGIN,
    };
  },
  tokenStatsSuccess: (data) => {
    return {
      type: actions.TOKEN_STATS_SUCCESS,
      data,
    };
  },
  tokenStatsErr: (err) => {
    return {
      type: actions.TOKEN_STATS_ERR,
      err,
    };
  },
};

export default actions;
