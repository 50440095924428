import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import communityDashboardReducer from './communityDashboard/reducers';
import usersReducer from './users/reducers';
import historyReducer from './history/reducers';
import dashboardReducer from './dashboard/reducers';
import modalsReducer from './modals/reducers';
import blockchainIntegrationReducer from './blockchainIntegration/reducers';

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  auth: authReducer,
  communityDashboard: communityDashboardReducer,
  users: usersReducer,
  history: historyReducer,
  dashboard: dashboardReducer,
  modals: modalsReducer,
  blockchain: blockchainIntegrationReducer,
  ChangeLayoutMode,
});

export default rootReducers;
