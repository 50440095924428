const DEFAULT_ACCOUNT_IDS = {
  ALICE: '5GrwvaEF5zXb26Fz9rcQpDWS57CtERHpNehXCPcNoHGKutQY',
  BOB: '5FHneW46xGXgs5mUiveU4sbTyGBzmstUspZC92UhjJM694ty',
};

const actions = {
  CRYPTO_WALLET_ACCOUNT_SELECT: 'CRYPTO_WALLET_ACCOUNT_SELECT',
  CRYPTO_WALLET_CONNECTION: 'CRYPTO_WALLET_CONNECTION',
  CRYPTO_WALLET_DISCONNECT: 'CRYPTO_WALLET_DISCONNECT',
  CRYPTO_WALLET_ERROR: 'CRYPTO_WALLET_ERROR',
  TEST_WALLET_CONNECTION: 'TEST_WALLET_CONNECTION',

  DEFAULT_POLKA_ACCOUNTS: [
    {
      address: DEFAULT_ACCOUNT_IDS.ALICE,
      meta: {
        genesisHash: '',
        name: 'Alice',
        source: 'polkadot-js',
      },
    },
    {
      address: DEFAULT_ACCOUNT_IDS.BOB,
      meta: {
        genesisHash: '',
        name: 'Bob',
        source: 'polkadot-js',
      },
    },
  ],

  cryptoWalletConnection: (data) => {
    return {
      type: actions.CRYPTO_WALLET_CONNECTION,
      payload: data,
    };
  },

  cryptoWalletDisconnect: () => {
    return {
      type: actions.CRYPTO_WALLET_DISCONNECT,
    };
  },

  cryptoWalletError: (data) => {
    return {
      type: actions.CRYPTO_WALLET_ERROR,
      payload: data,
    };
  },

  cryptoWalletAccountSelect: (data) => {
    return {
      type: actions.CRYPTO_WALLET_ACCOUNT_SELECT,
      payload: data,
    };
  },

  testWalletConnection: () => {
    return {
      type: actions.TEST_WALLET_CONNECTION,
    };
  },
};

export default actions;
