import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Routes, Route } from "react-router-dom";
import withAdminLayout from "../../layout/withAdminLayout";

const CommunityDashboard = lazy(() =>
  import("../../container/communityDashboard")
);
const Tokens = lazy(() => import("../../container/tokens"));
const Users = lazy(() => import("../../container/users"));
const Buy = lazy(() => import("../../container/buy"));
const Sell = lazy(() => import("../../container/sell"));
const Airdrop = lazy(() => import("../../container/airdrop"));
const History = lazy(() => import("../../container/history"));
const Integrations = lazy(() => import("../../container/integrations"));
const Dashboard = lazy(() => import("../../container/dashboard"));
const CreateToken = lazy(() => import("../../container/createToken"));
const Congratulations = lazy(() => import("../../container/congratulations"));
const Faq = lazy(() => import("../../container/faq"));

const Admin = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route path="" element={<Dashboard />} />
        <Route
          path="community-dashboard/:id"
          element={<CommunityDashboard />}
        />
        <Route path="tokens/:id" element={<Tokens />} />
        <Route path="users/:id" element={<Users />} />
        <Route path="buy/:id" element={<Buy />} />
        <Route path="sell/:id" element={<Sell />} />
        <Route path="airdrop/:id" element={<Airdrop />} />
        <Route path="history/:id" element={<History />} />
        <Route path="integrations/:id" element={<Integrations />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="create-token" element={<CreateToken />} />
        <Route path="congratulations/:id" element={<Congratulations />} />
        <Route path="faq" element={<Faq />} />
      </Routes>
    </Suspense>
  );
};

export default withAdminLayout(Admin);
